import {
  saveSuccessTitle,
  saveSuccessMessage,
  deleteSuccessTitle,
  deleteSuccessMessage,
  networkError,
  networkErrorMessage,
  loggedInTitle,
  loggedInDesc,
  loginFailed,
  loginFailedMessage,
  deactiveSuccessMessage,
} from 'locales/localization-de-DE'

import enUS from 'locales/localization-en-US'

console.log('env', process.env)

export const API_URL_INVENTORY = process.env.REACT_APP_BASE_URL_INVENTORY
export const API_URL = process.env.REACT_APP_BASE_URL_COMMON
export const API_URL_WORKORDER = process.env.REACT_APP_BASE_URL_WORKORDER
export const API_URL_REPORT = process.env.REACT_APP_BASE_URL_REPORT
export const API_URL_PRINT = process.env.REACT_APP_BASE_URL_PRINT
export const API_URL_MEDIA = process.env.REACT_APP_BASE_URL_MEDIA
export const API_URL_UPLOAD = process.env.REACT_APP_BASE_URL_UPLOAD

export const APPLICATION_NAME = 'Contract Management System'
export const APPLICAITON_OWNER = '© 2024 Softlare GmbH'
export const APPLICAITON_COPYRIGHT = '© 2024 Softlare GmbH. All rights reserved.'
export const VERSION = '1.0.12'

let msaveSuccessTitle = saveSuccessTitle
let msaveSuccessMessage = saveSuccessMessage
let mdeleteSuccessTitle = deleteSuccessTitle
let mdeleteSuccessMessage = deleteSuccessMessage
let mnetworkError = networkError
let mnetworkErrorMessage = networkErrorMessage
let mloggedInTitle = loggedInTitle
let mloggedInDesc = loggedInDesc
let mloginFailed = loginFailed
let mloginFailedMessage = loginFailedMessage
let mdeactiveSuccessMessage = deactiveSuccessMessage

const locale = localStorage.getItem('locale')
if (locale !== null && locale === 'de-DE') {
  msaveSuccessTitle = enUS.saveSuccessTitle
  msaveSuccessMessage = enUS.saveSuccessMessage
  mdeleteSuccessTitle = enUS.deleteSuccessTitle
  mdeleteSuccessMessage = enUS.deleteSuccessMessage
  mnetworkError = enUS.networkError
  mnetworkErrorMessage = enUS.networkErrorMessage
  mloggedInTitle = enUS.loggedInTitle
  mloggedInDesc = enUS.loggedInDesc
  mloginFailed = enUS.loginFailed
  mloginFailedMessage = enUS.loginFailedMessage
  mdeactiveSuccessMessage = enUS.deactiveSuccessMessage
}

export const SAVE_200_MSG = msaveSuccessTitle
export const SAVE_200_DESC = msaveSuccessMessage
export const DEL_200_MSG = mdeleteSuccessTitle
export const DEL_200_DESC = mdeleteSuccessMessage
export const DEAKTIVATE_200_DESC = mdeactiveSuccessMessage
export const NETWORK_ERROR = mnetworkError
export const NETWORK_ERROR_MESSAGE = mnetworkErrorMessage
export const LOGGED_IN = mloggedInTitle
export const LOGGED_IN_MESSAGE = mloggedInDesc
export const LOGIN_FAILED = mloginFailed
export const LOGIN_FAILED_MESSAGE = mloginFailedMessage
